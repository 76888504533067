import { ADVERTISER_LIST_URL, FEATURE_LIST_URL } from "src/constants/urls";
import axios from "src/utils/axios";

// get Request to get the feature list
export const getFeaturesList =  (advertiserId: number, accountId: number) => {
    return  axios.get(`${FEATURE_LIST_URL}/${advertiserId}/features?accountId=${accountId}`);
}
// get request to get the advertiser list
export const getAdvertisers = async (accountId: number) => {
    return axios.get(`${ADVERTISER_LIST_URL}${accountId}`);
}