import {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useReducer,
  useContext
} from 'react';
import { User } from 'src/models/user';
// import axios from 'src/utils/axios';
import { verify, JWT_SECRET } from 'src/utils/jwt';
import PropTypes, { string } from 'prop-types';
import base64 from 'base-64';
import axios from 'src/utils/axios';
import { LOGIN_URL, SELF_INGO_URL } from 'src/constants/urls';
import { doLogin, doLogout, getSelfInfo } from 'src/api/authApi';
import { ImpactContext } from './ImpactContext';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { asyncLocalStorage } from 'src/utils/asyncLocalStorage';
import expireCookie from 'src/utils/commonUtils';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  error: string | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (userName: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    error: string | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    error: string | null;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: null
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user, error } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      error
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user, error } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      error
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { setIsSuperAdmin, setViewMode } = useContext(ImpactContext);
  const {
    setDateRange,
    setSelectedFeature,
    setSelectedAdvertiser,
    setAdvertiserList
  } = useContext(ImpactContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        asyncLocalStorage.getItem('accessToken').then((accessToken) => {
          if (accessToken) {
            setSession(accessToken);
            asyncLocalStorage.getItem('user').then((user) => {
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: true,
                  user: JSON.parse(user),
                  error: null
                }
              });
            });
          } else {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null,
                error: null
              }
            });
          }
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            error: err.message
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (userName: string, password: string): Promise<void> => {
    doLogin(userName, password)
      .then(function (response) {
        var accessToken = 'Basic ' + base64.encode(userName + ':' + password);
        if (response.message === 'SUCCESS') {
          const user = response.object;
          const pendo = window.pendo;
          const visitor = { id: user?.email, impact_user: true };
          pendo.initialize({
            visitor,
            account: {
              id: user?.accountId,
              impact_user: true,
              email: user?.email,
              name: user?.name,
              role: user?.role,
              account_id: user?.accountId,
              account_name: user?.accountName,
              firstName: user?.firstName,
              lastName: user?.lastName,
              memberId: user?.memberId,
              permission: user?.permission
            }
          });
          if (user?.permissions?.includes(19)) {
            setIsSuperAdmin('true');
            localStorage.setItem('isSuperAdmin', 'true');
            localStorage.setItem('viewMode', 'admin');
            setViewMode('admin');
          } else {
            setIsSuperAdmin('false');
            localStorage.setItem('isSuperAdmin', 'false');
            localStorage.setItem('viewMode', 'user');
            setViewMode('user');
            setSearchParams({ accountId: user.accountId });
          }
          localStorage.setItem('user', JSON.stringify(user));
          setSession(accessToken);
          dispatch({
            type: 'LOGIN',
            payload: {
              user,
              error: null
            }
          });
        }
      })
      .catch(function (error) {
        if (
          error?.response?.status === 500 &&
          error?.response?.data?.message === 'FAILURE'
        ) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              error: error?.response?.data?.object
            }
          });
        }
      });
  };
  const logout = async (): Promise<void> => {

    // Logout API call
    await doLogout();
    // Clear application state
    setAdvertiserList([]);
    setDateRange(null);
    setSelectedFeature(null);
    setSelectedAdvertiser(null);
    
    // Clear local storage
    await localStorage.clear();
   
    //remove JSESSIONID cookie
    expireCookie('JSESSIONID');
    
    // Clear session state
    setSession(null);
    
    // Dispatch logout action
    dispatch({ type: 'LOGOUT' });
};

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post<{ accessToken: string; user: User }>(
      '/api/account/register',
      {
        email,
        name,
        password
      }
    );
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
