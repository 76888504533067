import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import StatusComingSoon from 'src/content/pages/Status/ComingSoon';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
const UserInfo = Loader(lazy(() => import('src/content/management/userInfo')));
const ManagementUsers = Loader(
  lazy(() => import('src/content/management/users/'))
);
const ManagementRoles = Loader(
  lazy(() => import('src/content/management/roles'))
);
const AdvertiserManagement = Loader(
  lazy(() => import('src/content/management/advertisers'))
);

let CompanyAccountRoutes = [];
if (process.env.REACT_APP_ENV === 'prod') {
  CompanyAccountRoutes = [
    {
      path: '',
      element: <Navigate to="personal-account" replace />
    },
    {
      path: 'personal-account',
      element: <UserInfo />
    },
    {
      path: 'users',
      element: <ManagementUsers />
    },
    {
      path: 'roles',
      element: <ManagementRoles />
    }
  ];
} else {
  CompanyAccountRoutes = [
    {
      path: '',
      element: <Navigate to="personal-account" replace />
    },
    {
      path: 'personal-account',
      element: <UserInfo />
    },
    {
      path: 'users',
      element: <ManagementUsers />
    },
    {
      path: 'roles',
      element: <ManagementRoles />
    },
    {
      path: 'advertisers',
      element: <AdvertiserManagement />
    }
  ];
}

export default CompanyAccountRoutes;
