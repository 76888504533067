export const ROLES = {
  INTERNAL_ADMIN: 1,
  USER_WRITE: 2,
  USER_READ: 3,
  ROLE_WRITE: 4,
  ROLE_READ: 5,
  ADVERTISER_WRITE: 6,
  ADVERTISER_READ: 7,
  BILLING_WRITE: 8,
  BILLING_READ: 9,
  CAMPAIGN_OVERVIEW: 10,
  CAMPAIGN_WRITE: 11,
  CAMPAIGN_READ: 12,
  ADVERTISER_INSIGHTS: 13,
  CAMPAIGN_INSIGHTS: 14,
  INDUSTRY_INTELLIGENCE: 15,
  PARTNER: 16,
  PIXELS: 17,
  INTERNAL_ADMIN_V2: 18,
  SUPER_USER: 19,
  GLOBAL_ADMIN: 20,
  CAMPAIGN_INSIGHTS_V2: 21,
  IMPACT: 22,
  CAMPAIGN_OPTIMIZER: 23,
  PUB_DEV: 24,
  PMP: 25,
  SITE_READ: 26,
  SITE_WRITE: 27,
  SITE_IMPACT: 28,
  CAMPAIGN_BULK_EDIT: 29,
  CLICK_TRACKING: 30,
  FACEBOOK_CAMPAIGN_WRITE: 31,
  FACEBOOK_CAMPAIGN_READ: 32,
  DATA_COOP: 33,
  EXPLORER_REPORT_READ: 34,
  PROPERT_MGMT: 35,
  RECONCILIATION: 36,
  ENTITY_LINKER: 37,
  CAMPAIGN_EDITOR: 38,
  RECONCILIATION_MASTER_USER: 39,
  DSP_DBM_MAPPING: 40,
  PERM_CDR_READ: 42,
  PERM_CDR_WRITE: 43,
  PERM_SETUP_READ: 44,
  PERM_SETUP_WRITE: 45,
  PERM_INFINITY_ENABLE: 46,
  PERM_INFINITY_DISABLE: 47,  
};
