import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Applications

const Setup = Loader(lazy(() => import('src/content/dashboards/Setup/Pixels')));
const WebsiteBulkUpload = Loader(
  lazy(
    () =>
      import(
        'src/content/dashboards/Setup/Pixels/Bulk Upload/WebsiteBulkUpload'
      )
  )
);
const AnalyticsBulkUpload = Loader(
  lazy(
    () =>
      import(
        'src/content/dashboards/Setup/Pixels/Bulk Upload/AnalyticsBulkUpload'
      )
  )
);
const ClickTrackerBulkUpload = Loader(
  lazy(
    () =>
      import(
        'src/content/dashboards/Setup/Pixels/Bulk Upload/ClickTrackerBulkUpload'
      )
  )
);
const SetupGroupPixel = Loader(
  lazy(() => import('src/content/dashboards/Setup/Grouping'))
);
const Logs = Loader(
  lazy(() => import('src/content/dashboards/Setup/Logs'))
);
let setupRoutes = [];

  setupRoutes = [
    {
      path: '',
      element: <Setup />
    },
    {
      path: 'pixels',
      element: <Setup />
    },
    {
      path: 'pixels/bulk-upload/website',
      element: <WebsiteBulkUpload />
    },
    {
      path: 'pixels/bulk-upload/analytics',
      element: <AnalyticsBulkUpload />
    },
    {
      path: 'pixels/bulk-upload/click-tracker',
      element: <ClickTrackerBulkUpload />
    },
    {
      path: 'attribution-groups',
      element: <SetupGroupPixel />
    },
    {
      path: 'logs',
      element: <Logs />
    }
  ];


export default setupRoutes;
