export function getFirstDayOfMonth(dateRange: Date) {
    if (dateRange === null || dateRange === undefined) {
      let date = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1, 0, 0, 0, 0);
      date.toUTCString();

      const mili = date.getTime();
      return mili.toString();
    } else {

      let date = new Date(dateRange.getFullYear(), dateRange.getMonth(), 1, 0, 0, 0, 0);
      const result = date.toString().split('GMT')[0].concat('GMT');
      let d = new Date(result);


      const mili = d.getTime();
      return mili.toString();
    }
  }