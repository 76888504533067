import range from "lodash/range";

const CustomYearDropdownPicker = ({ date, changeYear }) => {
  const currentYear = new Date().getFullYear();
  const yearOptions = range(2014, currentYear + 1); // Range from 2014 to the current year
  const selectedYear = date.getFullYear();


  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
      {/* Decrement Year Button */}
      <button
        onClick={() => changeYear(selectedYear - 1)}
        disabled={selectedYear <= 2014} // Disable if selected year is 2014 or less
        style={{
          border: "none",
          backgroundColor: selectedYear <= 2014 ? "#ccc" : "#CC0066", // Gray color when disabled
          color: "#fff",
          cursor: selectedYear <= 2014 ? "not-allowed" : "pointer", // Not-allowed cursor when disabled
          padding: "5px 10px",
          borderRadius: "4px",
        }}
      >
        {"<"}
      </button>
     

      {/* Year Dropdown */}
      <select
        style={{
          width: "50%",
          padding: "8px 12px",
          fontSize: "16px",
          fontFamily: "Arial, sans-serif",
          border: "1px solid #CC0066",
          borderRadius: "4px",
          backgroundColor: "#f5f5f5",
          color: "#333",
          cursor: "pointer",
          appearance: "none",
          WebkitAppearance: "none",
        }}
        value={date.getFullYear()}
        onChange={(e) => {
          const selectedYear = Number(e.target.value);
          changeYear(selectedYear);
        }}
      >
        {yearOptions.map((year) => (
          <option
            key={year}
            value={year}
            style={{
              backgroundColor: "#f5f5f5",
            }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#CC0066")}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#f5f5f5")}
          >
            {year}
          </option>
        ))}
      </select>
      <span
      style={{
        position: "absolute",
        right: "100px",
        top: "50%",
        transform: "translateY(-50%)",
        color: "#CC0066",
        pointerEvents: "none", // Ensures arrow does not interfere with select
        fontSize: "12px",
      }}
    >
      ▼
   </span>
      {/* Increment Year Button */}
      <button
        onClick={() => changeYear(selectedYear + 1)}
        disabled={selectedYear >= currentYear} // Disable if selected year is the current year or more
        style={{
          border: "none",
          backgroundColor: selectedYear >= currentYear ? "#ccc" : "#CC0066", // Gray color when disabled
          color: "#fff",
          cursor: selectedYear >= currentYear ? "not-allowed" : "pointer", // Not-allowed cursor when disabled
          padding: "5px 10px",
          borderRadius: "4px",
        }}
      >
        {">"}
      </button>
    </div>
  );
};

export default CustomYearDropdownPicker;
