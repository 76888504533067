import {
  Avatar,
  styled,
  alpha,
  lighten,
  Typography,
  Drawer,
  Button,
  CardActions,
  Dialog,
  IconButton
} from '@mui/material';

interface props {
  customColor?: string;
  value?: any;
  bg?: any;
  width?: any;
  height?: any;
  border?: any;
  margin?: any;
  solid?: any;
  maxHeight?: any;
  display?: any;
  alignItems?: any;
  justify?: any;
  marginB?: any;
  bgc?: any;
  shadow?: any;
  marginR?: any;
  position?: any;
  left?: any;
  top?: any;
  overflow?: any;
}
export const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
        color: ${theme.colors.primary.main};
        margin-right: ${theme.spacing(2)};
        margin-left: ${theme.spacing(2)};
        background: ${
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[10]
            : theme.colors.alpha.white[50]
        };
        box-shadow: ${
          theme.palette.mode === 'dark'
            ? '0 1px 0 ' +
              alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
              ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
            : '0px 2px 4px -3px ' +
              alpha(theme.colors.alpha.black[100], 0.4) +
              ', 0px 5px 16px -4px ' +
              alpha(theme.colors.alpha.black[100], 0.2)
        };
  `
);

export const AvatarWrapper = styled(Avatar)<props>(
  ({ theme, value, customColor, bg }) => `
      background: ${bg || null};
      color:  ${customColor};
      width: ${theme.spacing(value)};
      height: ${theme.spacing(value)};
`
);

export const TypographyPrimary = styled(Typography)<props>(
  ({ customColor }) => `
    color: ${customColor};
`
);

export const TypographySecondary = styled(Typography)<props>(
  ({ customColor }) => `
      color: ${customColor};
`
);

export const ButtonPartner = styled(Button)(
  ({ theme }) => `
      border: 1;


      .MuiSvgIcon-root {
        color: ${theme.colors.alpha.black[100]};
      }


`
);

export const CardActionsWrapper = styled(CardActions)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      padding: 0;
      display: block;
`
);


export const DialogWrapper = styled(Dialog)<props>(
  ({maxHeight, overflow, height}) => `
      .MuiDialog-paper {
        overflow: ${overflow || null};
      }
      .MuiDialog-container {
        height: ${height || null};
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: ${maxHeight || null}
    }
`
);

export const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

export const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

export const AvatarPrimary = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};
      width: ${theme.spacing(10)};
      height: ${theme.spacing(10)};
      margin: 0 auto ${theme.spacing(2)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(42)};
      }
`
);


export const AvatarSuccess = styled(Avatar)<props>(
  ({ bg, customColor, width, margin, height, bgc, shadow, value, left, marginR, top, position}) => `
      background-color: ${bgc || null};
      color: ${customColor || null};
      width: ${width};
      height: ${height};
      background: ${bg || null}
      box-shadow: ${shadow || null};
      left: ${left || null}
      top: ${top || null}
      position: ${position || null}
      margin-right: ${marginR || null}
      margin-left: ${margin || null}
      .MuiSvgIcon-root {
        font-size: ${value || null};
      }
`
);

export const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 300px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 300px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

export const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 280px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 280px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

export const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

export const DotLegend = styled('span')<props>(
  ({ border, width, height, margin, solid }) => `
    border-radius: 22px;
    width: ${width};
    height: ${height};
    display: inline-block;
    margin-right: ${margin};
    border: ${border || null} ${solid || null};
`
);


