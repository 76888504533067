import { Fragment, useRef, useState, ChangeEvent, useEffect } from 'react';
import axios from "src/utils/axios";
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  IconButton,
  Box,
  List,
  ListItem,
  Badge,
  ListItemAvatar,
  Button,
  Divider,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Typography,
  ListItemText,
  alpha,
  Popover,
  Tooltip,
  Avatar,
  styled,
  useTheme,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import Text from 'src/components/Text';
import Scrollbar from 'src/components/Scrollbar';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import CampaignIcon from '@mui/icons-material/Campaign';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import MarkChatReadTwoToneIcon from '@mui/icons-material/MarkChatReadTwoTone';
import { DotLegend } from 'src/content/materialUI/customStyle';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import { Advertiser } from 'src/models/advertiser';
import { ADVERTISER_LIST_URL } from 'src/constants/urls';


const ListWrapper = styled(List)(
  () => `
    .MuiListItem-root:last-of-type + .MuiDivider-root {
        display: none;
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function AdvertiserSwitcher() {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { user } = useAuth();

  const [AdvertiserList, setAdvertiserList] = useState<Advertiser[]>([]);
  const [selectedAdvertiser, setSelectedAdvertiser] =
    useState<Advertiser>(null);

  const changeAdvertiser = (item: Advertiser) => {
    setSelectedAdvertiser(item);
    localStorage.setItem('selectedAdvertiser', JSON.stringify(item));
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()), // Keeps all current parameters
      advertiserId: item.advertiserId.toString(), // Updates advertiserId
    });
    
    setOpen(false);
  };

  const getAdvertisers = async (): Promise<void> => {
    await axios
      .get(`${ADVERTISER_LIST_URL}${searchParams.get('accountId')}`)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('advertisers', JSON.stringify(res.data.list));
          setAdvertiserList(res.data.list);
          const selectedAdvId = res?.data?.list?.find((advertiser: any) => advertiser.advertiserId === Number(searchParams.get('advertiserId')))
          setSelectedAdvertiser(selectedAdvId)
          localStorage.setItem('selectedAdvertiser', JSON.stringify(selectedAdvId));
        }
      })
      .catch((err) => {
        //console.error(err);
      });
  };
  const chechSelectedAdvertiser = async (): Promise<void> => {
    if (searchParams.get('accountId')){
      getAdvertisers();
    }
  };

  useEffect(() => {
    chechSelectedAdvertiser();
  }, []);

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip arrow title={'Change Advertiser'} sx={{ ml: 1 }}>
        <Button
          ref={ref}
          onClick={handleOpen}
          size="small"
          variant="text"
          startIcon={<CampaignIcon fontSize="small" />}
        >
          {selectedAdvertiser !== null
            ? selectedAdvertiser.name
            : ' Select Advertiser'}
        </Button>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box minWidth={360} maxWidth={360}>
          <Box
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: `${alpha(theme.colors.alpha.black[100], 0.07)}`
            }}
          >
            <Button
              size="small"
              color="primary"
              startIcon={<MarkChatReadTwoToneIcon />}
              sx={{
                fontSize: theme.typography.pxToRem(12)
              }}
            >
              Select Advertise to change data accordingly
            </Button>
          </Box>

          <Divider />
          <Box
            sx={{
              height: 200
            }}
          >
            <Scrollbar>
              <ListWrapper disablePadding>
                {AdvertiserList.length && AdvertiserList?.map((item, index) => (
                  <Fragment key={index}>
                    <ListItem
                      sx={{
                        py: 1.5,
                        '&:hover': {
                          background: `${theme.colors.alpha.black[5]}`
                        }
                      }}
                      secondaryAction={
                        <Button
                          onClick={() => {
                            changeAdvertiser(item);
                          }}
                          size="small"
                          variant="text"
                          color="secondary"
                          sx={{
                            alignSelf: 'center',
                            padding: `${theme.spacing(0.5, 1.5)}`,
                            backgroundColor: `${theme.colors.secondary.lighter}`,
                            textTransform: 'uppercase',
                            fontSize: `${theme.typography.pxToRem(11)}`,
                            '&:hover': {
                              backgroundColor: `${theme.colors.secondary.main}`,
                              color: `${theme.palette.getContrastText(
                                theme.colors.secondary.main
                              )}`
                            }
                          }}
                        >
                          {t('Select')}
                        </Button>
                      }
                    >
                      <ListItemAvatar
                        sx={{
                          minWidth: 38,
                          mr: 1
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 38,
                            height: 38
                          }}
                          alt={item.name}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          flexGrow: 0,
                          maxWidth: '50%',
                          flexBasis: '50%'
                        }}
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              pb: 0.6
                            }}
                            color="text.primary"
                            variant="h5"
                          >
                            {item.name}
                          </Typography>
                        }
                        secondary={
                          item?.advertiserId ===
                            selectedAdvertiser?.advertiserId && (
                            <Box display="flex" alignItems="flex-start">
                              <DotLegend
                                margin = {theme.spacing(0.5)}
                                width = "10px"
                                height = "10px"
                                style={{
                                  background: `${theme.colors.success.main}`
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: `${theme.typography.pxToRem(11)}`,
                                  lineHeight: 1
                                }}
                                variant="body1"
                              >
                                <Text color="success">{'Active'}</Text>
                              </Typography>
                            </Box>
                          )
                        }
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </ListWrapper>
            </Scrollbar>
          </Box>
          <Divider />
        </Box>
      </Popover>
    </>
  );
}

export default AdvertiserSwitcher;
