import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import routeradmin from 'src/routeradmin';

import get from 'lodash/get';
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';
import { v4 as uuidv4 } from 'uuid';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import { SnackbarUtilsConfigurator } from './utils/snack';
import { User } from './models/user';
import { useContext, useEffect, useRef, useState } from 'react';
import { asyncLocalStorage } from './utils/asyncLocalStorage';
import { ImpactContext } from './contexts/ImpactContext';
import { RefreshProvider } from './contexts/RefreshProvider';

declare global {
  interface Window {
    pendo: any;
  }
}

// });

function App() {
  const content = useRoutes(router);
  const contentAdmin = useRoutes(routeradmin);
  // let isSuperAdmin = localStorage.getItem('isSuperAdmin');
  // const [isSuperAdmin, setIsSuperAdmin] = useState(
  //   localStorage.getItem('isSuperAdmin')
  // );
  const { isSuperAdmin, setIsSuperAdmin } = useContext(ImpactContext);

  const auth = useAuth();
  const { user } = useAuth();
  let userLocal: User;
  if (user === null && localStorage.getItem('user') !== null) {
    userLocal = JSON.parse(localStorage.getItem('user'));
    const pendo = window.pendo;
    const visitor = { id: userLocal?.email, impact_user: true };
    pendo.initialize({
      visitor,
      account: {
        id: userLocal?.accountId,
        impact_user: true,
        email: userLocal?.email,
        name: userLocal?.name,
        role: userLocal?.role,
        account_id: userLocal?.accountId,
        account_name: userLocal?.accountName,
        firstName: userLocal?.firstName,
        lastName: userLocal?.lastName,
        memberId: userLocal?.memberId,
        permission: userLocal?.permission
      }
    });
  }
  useEffect(() => {
    const isReloaded = sessionStorage.getItem('reloaded');
    const viewMode = localStorage.getItem('viewMode');
    const storedSuperAdmin = localStorage.getItem('isSuperAdmin');

    // If it's a fresh access and the user is a super user
    if (!isReloaded && viewMode === 'admin') {
        setIsSuperAdmin('true');
        localStorage.setItem('isSuperAdmin', 'true');
    }

    // If it's a reload, restore the isSuperAdmin state from localStorage
    if (isReloaded) {
        setIsSuperAdmin(storedSuperAdmin);
    }

    // Set the session storage flag to differentiate future refreshes
    sessionStorage.setItem('reloaded', 'true');
}, [])
  useEffect(() => {}, [isSuperAdmin]);
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <SnackbarUtilsConfigurator />
          <CssBaseline />
          {auth.isInitialized ? (
            isSuperAdmin === 'true' ? (
              <RefreshProvider> {contentAdmin} </RefreshProvider>
            ) : (
              <RefreshProvider>{content}</RefreshProvider>
            )
          ) : (
            <AppInit />
          )}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
