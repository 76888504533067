import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account

const LoginCover = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Cover'))
);

const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);
const SetNewPassword = Loader(
  lazy(() => import('src/content/pages/Auth/NewPassword'))
);

const accountRoutes = [
  {
    path: 'login',
    element: <LoginCover />
  },
  {
    path: 'login-cover',
    element: <LoginCover />
  },
  {
    path: 'recover-password',
    element: <RecoverPassword />
  },
  {
    path: 'set-new-password',
    element: <SetNewPassword />
  },
  {
    path: 'set-new-password/:id',
    element: <SetNewPassword />
  }
];

export default accountRoutes;
