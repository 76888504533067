import { Tooltip } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { ThemeContext } from 'src/theme/ThemeProvider';
import { ImpactContext } from 'src/contexts/ImpactContext';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
const SidebarMode: FC = () => {
  const {sidebarMode, setSidebarMode} = useContext(ImpactContext);
  const curSidebarMode = localStorage.getItem('SidebarMode') || 'Expanded';
  //const [theme, setTheme] = useState(curThemeName);
  const changeSidebarMode = (): void => {
    const newSidebarMode = sidebarMode === 'Expanded' ? 'Collapsed' : 'Expanded';
    setSidebarMode(newSidebarMode);
    localStorage.setItem('SidebarMode', newSidebarMode);
};
  return (
    <>
      <Tooltip
        arrow
        title={
          sidebarMode === 'Expanded'
            ? 'Collapse Sidebar'
            : 'Expand Sidebar'
        }
      >
        <span onClick={changeSidebarMode}>
          {' '}
          {/* Wrapper span for clickable icons */}
          {sidebarMode === 'Expanded' ? (
            <MenuOpenIcon color="action" style={{ cursor: 'pointer' }} />
          ) : (
            <MenuIcon color="action" style={{ cursor: 'pointer' }} />
          )}
        </span>
      </Tooltip>
    </>
  );
};

export default SidebarMode;
