import { Tooltip } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { ThemeContext } from 'src/theme/ThemeProvider';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Represents light theme
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Represents dark theme

const ThemeSwitcher: FC = () => {
  const setThemeName = useContext(ThemeContext);
  const curThemeName = localStorage.getItem('appTheme') || 'PurpleFlowTheme';
  const [theme, setTheme] = useState(curThemeName);
  const changeTheme = (): void => {
    const newTheme = theme === 'PurpleFlowTheme' ? 'GreenFieldsTheme' : 'PurpleFlowTheme';
    setTheme(newTheme);
    setThemeName(newTheme);
    localStorage.setItem('appTheme', newTheme);
};
  return (
    <>
      <Tooltip
        arrow
        title={
          theme === 'PurpleFlowTheme'
            ? 'Switch to Dark Theme'
            : 'Switch to Light Theme'
        }
      >
        <span onClick={changeTheme}>
          {' '}
          {/* Wrapper span for clickable icons */}
          {theme === 'PurpleFlowTheme' ? (
            <Brightness4Icon color="action" style={{ cursor: 'pointer' }} />
          ) : (
            <Brightness7Icon color="action" style={{ cursor: 'pointer' }} />
          )}
        </span>
      </Tooltip>
    </>
  );
};

export default ThemeSwitcher;
