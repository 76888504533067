import { useRef } from 'react';

import {
  IconButton,
  Badge,
  alpha,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { FeedbackOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    boxShadow: theme.shadows[24],
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Feedback({sideBar}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const ref = useRef<any>(null);
  const iconButtonStyle = sideBar === 'collapsed' ? {
    mt: 2,
    background: `${theme.sidebar.menuItemIconColor}`,
    color: `${theme.colors.alpha.trueWhite[70]}`,
    transition: `${theme.transitions.create(['all'])}`,

    '&:hover': {
      background: `${alpha(theme.colors.alpha.black[100], 0.8)}`
    }
  } : {
    background: `${theme.colors.alpha.trueWhite[10]}`,
              color: `${theme.colors.alpha.trueWhite[70]}`,
              transition: `${theme.transitions.create(['all'])}`,

              '&:hover': {
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`
              }
}

  return (
    <>
      <LightTooltip
          placement="top"
          arrow
          title={t('Send Feedback')}
        >
          <IconButtonWrapper
            className="feedback-button-wrapper"
            color="secondary"
            ref={ref}
            sx={iconButtonStyle}
          >
            <FeedbackOutlined fontSize="small" />
          </IconButtonWrapper>
      </LightTooltip>
    </>
  );
}

export default Feedback;
