import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';

export interface MenuItem {
  link?: string;
  icon?: any;
  badge?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      // {
      //   name: '',
      //   icon: BackupTableTwoToneIcon,
      //   link: '/internalAdmin/accounts',

      //   // items: [
      //   //   {
      //   //     name: 'Extended sidebar',
      //   //     link: '/extended-sidebar/dashboards'
      //   //   },
      //   //   {
      //   //     name: 'Accent header',
      //   //     link: '/accent-header/dashboards',
      //   //     badge: ''
      //   //   },
      //   //   {
      //   //     name: 'Accent sidebar',
      //   //     link: '/accent-sidebar/dashboards'
      //   //   },
      //   //   {
      //   //     name: 'Boxed sidebar',
      //   //     link: '/boxed-sidebar/dashboards'
      //   //   },
      //   //   {
      //   //     name: 'Collapsed sidebar',
      //   //     link: '/collapsed-sidebar/dashboards'
      //   //   },
      //   //   {
      //   //     name: 'Bottom navigation',
      //   //     link: '/bottom-navigation/dashboards'
      //   //   },
      //   //   {
      //   //     name: 'Top navigation',
      //   //     link: '/top-navigation/dashboards'
      //   //   }
      //   // ]
      // },
      {
        name: 'Accounts',
        icon: BackupTableTwoToneIcon,
        link: '/admin/accounts',
      },
      {
        name: 'Users',
        icon: SmartToyTwoToneIcon,
        link: '/admin/users',
   
      },
      {
        name: 'Destinations / Tourism',
        icon: HealthAndSafetyTwoToneIcon,

        link: '/admin/destinations',
       
      },
    
 
    ]
  }
];

export default menuItems;
