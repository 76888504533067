import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  Alert,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';

import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';
import Scrollbar from 'src/components/Scrollbar';

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 650px;
  }
  width: 100%;
  display: flex;
  align-items: center;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
  
    
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 700px;
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.7); /* Black w/opacity/see-through */
  color: white;

  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 60%;
  padding: 20px;
  text-align: center;
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(24)};
`
);

function LoginCover() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Login - Impact</title>
      </Helmet>
      <Content>
        <SidebarWrapper
          sx={{
            display: { xs: 'none', sm: 'none', md: 'flex' },
            backgroundImage: (theme) =>
              `url('static/images/placeholders/covers/adara-login-cover.webp')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        >
          <Scrollbar>
            <SidebarContent>
              {/* <Logo /> */}
              <Box mt={4}>
                <TypographyH1
                  variant="h1"
                  sx={{
                    mb: 10,
                    fontSize: 30
                  }}
                >
                  {t('Welcome to Adara Impact')}
                </TypographyH1>
                <Box
                  style={{ transform: 'scale(1.5)' }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    textAlign: 'center',
                    position: 'relative',
                    height: 45,
                    mb: 6
                  }}
                >
                  <Logo />
                </Box>
                <Typography
                  variant="body2"
                  lineHeight={2}
                  sx={{
                    my: 3
                  }}
                >
                  Adara Impact is a media and site data measurement application
                  that enables destination marketers to analyze and optimize
                  their marketing strategy using confirmed travel transaction
                  data. Due to the nature of the DMO space, there is limited to
                  no view over the conversions generated through their digital
                  media,{' '}
                  <strong>
                    so Adara Impact fills this gap by accessing the Adara Travel
                    Consortium of over 1.2B unique travelers globally,
                    generating billions of travel events annually.
                  </strong>
                  <br />
                  <Link
                    href="https://docs.adara.com/impact/impact-overview"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read docs
                  </Link>
                </Typography>
              </Box>
            </SidebarContent>
          </Scrollbar>
        </SidebarWrapper>
        <MainContent sx={{}}>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              {method === 'JWT' && <JWTLogin />}
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
