import React from 'react';

interface RefreshContextProps {
  lastRefreshTime: Date | null;
  currentFeature: string | null;
  setLastRefreshTime: React.Dispatch<React.SetStateAction<Date | null>>;
  setCurrentFeature: React.Dispatch<React.SetStateAction<string | null>>;
}

export const RefreshContext = React.createContext<RefreshContextProps | undefined>(undefined);
