import {
  CUSTOM_DATE_RANGE_LIST_URL,
  CUSTOM_DATE_RANGE_URL,
  DELETE_CUSTOM_DATE_RANGE_URL,
  KEY_PROFILE_INSIGHTS_URL,
  MARKETIN_ACTIVITY_URL,
  SITE_PIXELS_URL,
  TOP_FLIGHT_ORIGIN_URL,
  TOP_HOTEL_ORIGIN_URL,
  TOP_LINE_STATS_URL,
  TRIP_PLANNING_WINDOW_URL
} from 'src/constants/urls';
import axios from 'src/utils/axios';

//POST request to request custom date range
export const reqCustomDateRange = async (
  startDate: string,
  endDate: string,
  advertiserId: number
) => {
  const list = [];
  list.push({
    startDate: startDate,
    endDate: endDate,
    advertiserId: advertiserId,
    daysRemaining: 0
  });
  const response = await axios.post(
    `${CUSTOM_DATE_RANGE_URL}/${advertiserId}/custom_date_ranges`,
    {
      list
    }
  );
  return response;
};

// GET request to get top line stats
export const getTopLineStats = async (
  isCustomDate: boolean,
  advertiserId: number,
  startDate: string
) => {
  if(isCustomDate === true) {
    const response = await axios.get(
      `${TOP_LINE_STATS_URL}/${advertiserId}/top_line_stats?partnerId=&customDateRangeId=${startDate}`
    );
    return response;
  
  } else if(isCustomDate === false) {

    const response = await axios.get(
      `${TOP_LINE_STATS_URL}/${advertiserId}/top_line_stats?partnerId=&startDate=${startDate}`
    );
    return response;
  }
};

// GET request to get top hotel origin
export const getTopHotelOrigin = async (
  isCustomDate: boolean,
  advertiserId: number,
  startDate: string
) => {
  if(isCustomDate === true) {
    const response = await axios.get(
      `${TOP_HOTEL_ORIGIN_URL}/${advertiserId}/top_hotel_origin_markets?customDateRangeId=${startDate}`
    );
    return response;
  

  } else if(isCustomDate === false) {

    const response = await axios.get(
      `${TOP_HOTEL_ORIGIN_URL}/${advertiserId}/top_hotel_origin_markets?startDate=${startDate}`
    );
    return response;
  }

};

// GET request to get top flight origin
export const getTopFlightOrigin = async (
  isCustomDate: boolean,
  advertiserId: number,
  startDate: string
) => {
  if(isCustomDate === true) {
    const response = await axios.get(
      `${TOP_FLIGHT_ORIGIN_URL}/${advertiserId}/top_flight_origin_markets?customDateRangeId=${startDate}`
    );
    return response;
  

  } else if(isCustomDate === false) {
    const response = await axios.get(
      `${TOP_FLIGHT_ORIGIN_URL}/${advertiserId}/top_flight_origin_markets?startDate=${startDate}`
    );
    return response;
  }

};

// GET request to get travelere profile insight
export const getTravelerProfileInsight = async (
  isCustomDate: boolean,
  advertiserId: number,
  startDate: string
) => {
  if(isCustomDate === true) {
    const response = await axios.get(
      `${KEY_PROFILE_INSIGHTS_URL}/${advertiserId}/traveler_profile_insights?partnerId=&customDateRangeId=${startDate}`
    );
    return response;
  

  } else if(isCustomDate === false) {
    const response = await axios.get(
      `${KEY_PROFILE_INSIGHTS_URL}/${advertiserId}/traveler_profile_insights?partnerId=&startDate=${startDate}`
    );
    return response;
  }


};

// GET request to get trip planning window
export const getTripPlanningWindow = async (
  isCustomDate: boolean,
  advertiserId: number,
  startDate: string
) => {
  if(isCustomDate === true) {
    const response = await axios.get(
      `${TRIP_PLANNING_WINDOW_URL}/${advertiserId}/trip_planning_window?partnerId=&customDateRangeId=${startDate}`
    );
    return response;
  

  } else if(isCustomDate === false) {
    const response = await axios.get(
      `${TRIP_PLANNING_WINDOW_URL}/${advertiserId}/trip_planning_window?startDate=${startDate}`
    );
    return response;
  }


};
// GET request to get marketing activity
export const getMarketingActivity = async (
  isCustomDate: boolean,
  advertiserId: number,
  startDate: string
) => {
  if(isCustomDate === true) {

    const response = await axios.get(
      `${MARKETIN_ACTIVITY_URL}/${advertiserId}/marketing_activity?partnerId=&customDateRangeId=${startDate}`
    );
    return response;
  } else if(isCustomDate === false) {

    const response = await axios.get(
      `${MARKETIN_ACTIVITY_URL}/${advertiserId}/marketing_activity?partnerId=&startDate=${startDate}`
    );
    return response;
  }


};

// GET request tog  get custom date range list
export const getCustomDateRangeList = async (advertiserId: number) => {
  const response = await axios.get(
    `${CUSTOM_DATE_RANGE_LIST_URL}/${advertiserId}/custom_date_ranges`
  );
  return response;
};

// DELETE request to delete custom date range
export const deleteCustomDateRange = async (customDateRangeId: number) => {
  const response = await axios.delete(
    `${DELETE_CUSTOM_DATE_RANGE_URL}/${customDateRangeId}`
  );
  return response;
};
