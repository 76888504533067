import { FC, ReactNode, useContext, useRef } from 'react';
import { Box, alpha, lighten, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ThemeSettings from 'src/components/ThemeSettings';

import Sidebar from './Sidebar';
import Header from './Header';
import { ImpactContext } from 'src/contexts/ImpactContext';
import CollapsedSidebar from './SidebarCollapsed';

interface ExtendedSidebarLayoutProps {
  children?: ReactNode;
}

const ExtendedSidebarLayout: FC<ExtendedSidebarLayoutProps> = () => {
  const theme = useTheme();
  const exportRef = useRef<HTMLDivElement>(null);
  const {sidebarMode, setSidebarMode} = useContext(ImpactContext);
  const boxStyles = {
    position: 'relative',
    zIndex: 5,
    display: 'block',
    flex: 1,
    pt: `${theme.header.height}`,
    [theme.breakpoints.up(sidebarMode === "Expanded" ? 'lg' : 'md')]: {
      ml: sidebarMode === "Expanded" ? `${theme.sidebar.width}` : theme.spacing(12)
    }
  };
  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`
          }
        }}
      >
        <Header exportRef={exportRef} />

        { sidebarMode === "Expanded" ? <Sidebar /> : <CollapsedSidebar/>}
        <Box ref={exportRef} sx={boxStyles}>
        <Box display="block">
          <Outlet />
        </Box>
        <ThemeSettings />
      </Box>
      </Box>
    </>
  );
};

export default ExtendedSidebarLayout;
