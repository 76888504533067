import axios from 'src/utils/axios';
import { SITE_LAST_REFRESH_TIME_URL, MEDIA_LAST_REFRESH_TIME_URL, PIXELS_LAST_REFRESH_TIME_URL } from '../constants/urls';

// get Request to get the last refresh time for sites
export const getSiteLastRefreshTime = () => {
    return axios.get(`${SITE_LAST_REFRESH_TIME_URL}`);
}

// get Request to get the last refresh time for media
export const getMediaLastRefreshTime = () => {
    return axios.get(`${MEDIA_LAST_REFRESH_TIME_URL}`);
}

// get Request to get the last refresh time for pixels
export const getPixelsLastRefreshTime = () => {
    return axios.get(`${PIXELS_LAST_REFRESH_TIME_URL}`);
}
