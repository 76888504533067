import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import StatusComingSoon from 'src/content/pages/Status/ComingSoon';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
const AccountAdmin = Loader(lazy(() => import('src/content/admin/accounts')));
const UserInfo = Loader(lazy(() => import('src/content/management/userInfo')));

let AdminRoutes = [];
AdminRoutes = [
  {
    path: '',
    element: <Navigate to="accounts" replace />
  },
  {
    path: 'accounts',
    element: <AccountAdmin />
  }
  // {
  //   path: '',
  //   element: <Navigate to="accounts" replace />
  // },
  // {
  //   path: 'accounts',
  //   element: <AccountAdmin />
  // }
];

export default AdminRoutes;
