import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const RedirectToFeatures = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the current path is 'home'
    if (location.pathname.includes('/home')) {
      // Extracting query parameters
      const queryParams = new URLSearchParams(location.search);

      // Redirecting to the 'features' route with the same query parameters
      navigate(`/features${location.search}`, { replace: true });
    }
  }, [navigate, location]);

  return null;
};
