import { useContext } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Button,
  Zoom
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderSearch from './Search';
import HeaderMenu from './Menu';
import exportAsImage from 'src/utils/exportASImage';
import {
  DownloadForOfflineOutlined,
  DownloadOutlined
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import ThemeSwitcher from './ThemeSwitcher';
import SidebarMode from './SidbarMode';
import { ImpactContext } from 'src/contexts/ImpactContext';

const HeaderWrapper = styled(Box)(
  ({ theme, sidebarMode }: { theme: any; sidebarMode: string }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        ${
          sidebarMode === 'Expanded'
            ? `
            @media (min-width: ${theme.breakpoints.values.lg}px) {
                left: ${theme.sidebar.width};
                width: auto;
            }`
            : `
            @media (min-width: ${theme.breakpoints.values.md}px) {
                left: ${theme.spacing(12)};
                width: auto;
            }`
        }
`
);
type Props = {
  exportRef: React.RefObject<HTMLDivElement>;
};
function Header(props: Props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const {sidebarMode} = useContext(ImpactContext);
  const theme = useTheme();
  const fileName = () => {
    const url = window.location.href;
    const lastSegment = url.substring(
      url.lastIndexOf('/') + 1,
      url.indexOf('?')
    );

    // Parse the query string parameters from the URL
    const params = new URLSearchParams(window.location.search);

    // Build the desired string by joining the parameters with the last segment
    const result = `${lastSegment}-${[...params.keys()]
      .map((key) => `${key}=${params.get(key)}`)
      .join('-')}`;
    return result;
  };

  const handleExport = () => {
    if (props.exportRef.current) {
      enqueueSnackbar('Download In Progress...', {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom,
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)}>
            <CloseTwoToneIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      exportAsImage(props.exportRef.current, fileName()).then((val) => {
        closeSnackbar();
        enqueueSnackbar('Download Completed', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom,
          autoHideDuration: 3000,
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)}>
              <CloseTwoToneIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });
      });
    }
  };
  return (
    <HeaderWrapper sidebarMode={sidebarMode} theme={theme}
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <SidebarMode/>
      <ThemeSwitcher />
      </Stack>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <Tooltip arrow title={'Download Full Page As PNG Image'} sx={{ ml: 1 }}>
          <IconButton
            sx={{
              ml: 1,
              color: 'gray'
            }}
            onClick={handleExport}
            size="small"
          >
            <DownloadOutlined />{' '}
          </IconButton>
        </Tooltip>
        <Box sx={{ ml: 2 }}>
          <HeaderUserbox />
        </Box>

        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
