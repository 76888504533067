import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import './calendar.css';

interface ICustomDatePicker {
  datePickerRef?: React.RefObject<DatePicker>;
  selected: Date | null,
  onChange: (date: any, event?: any) => void;
  // selectsMultiple: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  showMonthDropdown: boolean;
  showYearDropdown: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const CustomDatePicker : React.FC<ICustomDatePicker> = forwardRef(({
  datePickerRef,
  selected,
  onChange,
  selectsStart,
  startDate,
  endDate,
  showMonthDropdown,
  showYearDropdown,
  minDate,
  maxDate,
}) => {
    return (
      <>
        <DatePicker
          ref={datePickerRef}              // Ref object to focus on the datepicker
          selected={selected}              // The currently selected date
          onChange={onChange}              // Function to call when date changes
          selectsStart={selectsStart}      // Determines if it selects the start date (for ranges)
          startDate={startDate}            // Start date (if using date range)
          endDate={endDate}                // End date (if using date range)
          showMonthDropdown={showMonthDropdown} // Whether to show the month dropdown
          showYearDropdown={showYearDropdown}   // Whether to show the year dropdown
          minDate={minDate}                // Minimum selectable date
        maxDate={maxDate}                   // Maximum selectable date
          dropdownMode="select"
        />
      </>
    );
  });

  export default CustomDatePicker