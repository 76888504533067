import { useState, useEffect, ReactNode, FC } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getSiteLastRefreshTime,
  getMediaLastRefreshTime,
  getPixelsLastRefreshTime
} from 'src/api/lastRefresh'; //
import { RefreshContext } from './RefreshContextProps';

export const RefreshProvider: FC<{children: ReactNode}>  = ({ children }) => {
  const [lastRefreshTime, setLastRefreshTime] = useState<Date | null>(
    new Date(localStorage.getItem('lastRefreshTime') || '')
  );
  const [currentFeature, setCurrentFeature] = useState<string | null>(
    localStorage.getItem('currentFeature')
  );
  const location = useLocation();

  useEffect(() => {
    const checkFeatureAndRefreshTime = async () => {
      const url = location.pathname;
      let feature: string | null = null;
      let refreshTime: Date | null = null;

      if (url.includes('insights/media')) {
        feature = 'media';
        const response = await getMediaLastRefreshTime();
        refreshTime = new Date(response.data.timestamp);
      } else if (url.includes('insights/site')) {
        feature = 'site';
        const response = await getSiteLastRefreshTime();
        refreshTime = new Date(response.data.timestamp);
      } else if (url.includes('insights/setup')) {
        feature = 'setup';
        const response = await getPixelsLastRefreshTime();
        refreshTime = new Date(response.data.timestamp);
      }

      setCurrentFeature(feature);
      setLastRefreshTime(refreshTime);

      // Save to local storage
      localStorage.setItem('currentFeature', feature || '');
      localStorage.setItem('lastRefreshTime', refreshTime?.toISOString() || '');
    };

    checkFeatureAndRefreshTime();
  }, [location]);

  return (
    <RefreshContext.Provider
      value={{
        lastRefreshTime,
        currentFeature,
        setLastRefreshTime,
        setCurrentFeature
      }}
    >
      {children}
    </RefreshContext.Provider>
  );
};
