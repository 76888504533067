import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import StatusComingSoon from 'src/content/pages/Status/ComingSoon';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
const AccountAdmin = Loader(lazy(() => import('src/content/admin/accounts')));
const AddAccountAdmin = Loader(
  lazy(() => import('src/content/admin/accounts/addAccount'))
);
const UserAdmin = Loader(lazy(() => import('src/content/admin/users')));
const AddUser = Loader(lazy(() => import('src/content/admin/users/addUser')));
const Destination = Loader(lazy(() => import('src/content/admin/destination')));

let AdminRoutes = [];
AdminRoutes = [
  {
    path: '',
    element: <Navigate to="/admin/accounts" replace />
  },
  {
    path: '/admin/accounts',
    element: <AccountAdmin />
  },
  {
    path: '/admin/accounts/add',
    element: <AddAccountAdmin />
  },
  {
    path: '/admin/accounts/edit/:id',
    element: <AddAccountAdmin />
  },
  {
    path: '/admin/users',
    element: <UserAdmin />
  },
  {
    path: '/admin/users/add',
    element: <AddUser />
  },
  {
    path: '/admin/users/edit/:id',
    element: <AddUser />
  },
  {
    path: '/admin/destinations',
    element: <Destination />
  },
  {
    path: '/admin/pixelMapping',
    element: <StatusComingSoon date={new Date()} status="true" />
  }
  // {
  //   path: '',
  //   element: <Navigate to="accounts" replace />
  // },
  // {
  //   path: 'accounts',
  //   element: <AccountAdmin />
  // }
];

export default AdminRoutes;
