import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { ImpactProvider } from './contexts/ImpactContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      retry: false
    }
  }
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <BrowserRouter>
        <ImpactProvider>
          <SidebarProvider>
            <ScrollTop />
            <AuthProvider>
              <App />
            </AuthProvider>
          </SidebarProvider>
        </ImpactProvider>
      </BrowserRouter>
    </HelmetProvider>
    {/* <ReactQueryDevtools initialIsOpen={true}/> Only to be uncommented in the local*/}
  </QueryClientProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
