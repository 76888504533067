import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/content/pages/Auth/Login/Cover';
import StatusMaintenance from 'src/content/pages/Status/Maintenance';
import UserInfo from 'src/content/management/userInfo';
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout';

interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated = ({ children }) => {
  const auth = useAuth();
  const { user } = useAuth();
  const user1 = JSON.parse(localStorage.getItem('user'));

  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  // if (
  //   user !== null
  //     ? !user.permissions.includes(41)
  //     : user1 !== null && !user1.permissions.includes(41)
  // ) {
  //   return <StatusMaintenance />;
  // }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
