import { Box } from '@mui/material';
import AdvertiserSwitcher from './AdvertiserSwitcher';
import { useSearchParams } from 'react-router-dom';
import DateSelection from './Dateselection';

function HeaderButtons() {
  const [searchParams] = useSearchParams();

  return (
    <Box sx={{}}>
      {/* <HeaderNotifications />
      <Chat /> */}

      {searchParams.get('advertiserId') !== null &&
        window.location.href.indexOf('insights') > -1 &&
        window.location.href.indexOf('private-consortium') === -1 && (
          <DateSelection />
        )}
      {/* <LanguageSwitcher /> */}

      {searchParams.get('advertiserId') !== null &&
        window.location.href.indexOf('insights') > -1 && <AdvertiserSwitcher />}
    </Box>
  );
}

export default HeaderButtons;
