import axios from 'axios';
const { REACT_APP_BASE_URL } = process.env;
import SnackbarUtils   from 'src/utils/snack';
import {AxiosError, AxiosInstance, InternalAxiosRequestConfig , AxiosResponse} from "axios";

const onRequest = (config: InternalAxiosRequestConfig ): InternalAxiosRequestConfig  => {
    return config;
}
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
}
const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
}
const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error).catch((error) => {
        if (error.response) {

            const { status, data } = error.response;
            switch (status) {
                case 400:
                  if(data?.errors?.length > 0 && data?.errors[0]?.message){
                    SnackbarUtils.error(data?.errors[0]?.message);
                  }
                    break;
                case 401:
                    SnackbarUtils.error('Unauthorized');
                    break;
                case 403:
                    SnackbarUtils.error('Forbidden');
                    break;
                case 404:
                    console.clear();
                    break;
                case 409:
                    SnackbarUtils.warning('Conflict');
                    break;
                case 503:
                    SnackbarUtils.error('Server Unavailable');
                    break;    
                case 500:
                    console.log("Internal Server Error");
                    break;
                default:
                    console.log("Unknown Error");
                    break;
            }
        }
        return Promise.reject(error);
    }
    );
}
export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}

const axios1 = axios.create({
  withCredentials: true,
  baseURL: REACT_APP_BASE_URL,
});
setupInterceptorsTo(axios1);
export default axios1;
//export const mock = new AxiosMockAdapter(axiosInt, { delayResponse: 0 });

//export default axiosInt;
