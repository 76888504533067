import html2canvas from "html2canvas";

const exportAsImage = async (element, imageFileName) => {
  const html = document.documentElement;
  const body = document.body;
  const { width, height } = getDocumentSize();

  html.style.width = width + "px";
  body.style.width = width + "px";
  html.style.height = height + "px";
  body.style.height = height + "px";

  const canvas = await html2canvas(document.body, {
    scale: 1,
    useCORS: true,
    scrollX: 0,
    scrollY: -window.scrollY,
    windowWidth: window.innerWidth,
    windowHeight: height,
  });

  const image = canvas.toDataURL("image/png", 1.0);
  downloadImage(image, imageFileName);

  html.style.width = null;
  body.style.width = null;
  html.style.height = null;
  body.style.height = null;
};

const getDocumentSize = () => {
  const body = document.body;
  const html = document.documentElement;

  const width = Math.max(
    body.scrollWidth,
    body.offsetWidth,
    html.clientWidth,
    html.scrollWidth,
    html.offsetWidth
  );

  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  return { width, height };
};

const downloadImage = (blob, fileName) => {
  const fakeLink = document.createElement("a");
  fakeLink.download = fileName;
  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);
};

export default exportAsImage;
